<template>
  <div>
    <div class="all_context">
      <div class="head">
        <div class="img">
          <video
            v-if="headData.videoList"
            :src="headData.videoList[0]"
            :controls="isControls"
          ></video>
          <div v-if="headData.videoList == null && headData.imageList">
            <img :src="headData.imageList[0]" alt="" />
          </div>

          <div v-if="headData.videoList">
            <div class="videoSuspend" v-if="isSuspend" @click="clickSuspend">
              <i class="el-icon-video-play"></i>
            </div>
          </div>
        </div>
        <div class="describe">
          <div class="title_label">
            <div class="left">
              <div class="title">{{ headData.informationName }}</div>
              <div class="type">
                资讯类型：
                <span v-if="headData.informationType == 1">应用场景</span>
                <span v-if="headData.informationType == 2">数智技术</span>
                <span v-if="headData.informationType == 3">竞情</span>
                <span v-if="headData.informationType == 4">案例</span>
                <span v-if="headData.informationType == 5">产品</span>
                <span v-if="headData.informationType == 6">服务商</span>
              </div>
              <div class="issueName">
                发布机构/人：<span>{{ headData.issueName }}</span>
              </div>
              <div class="label">
                <div class="item" v-for="(item, index) in headData.labelList" :key="index">
                  #{{ item }}
                </div>
              </div>
            </div>
            <div class="right">
             <div class="total_num">
                <span>{{ total }}</span>
             </div>
             <div>
                <span>已匹配企业数</span>
             </div>
            </div>
          </div>
        
        </div>
      </div>
      <div class="context">
        <div class="state">
          <div class="left">
            <el-button style="margin-right: 5px" @click="updateOffShelf">批量下架</el-button>
          
          </div>

          <div class="right">
           
            <div class="seach_box">
          <el-input
            placeholder="请输入企业全称"
            v-model="query.queryConditions"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="search()">搜索</el-button>
          </el-input>
        </div>
          </div>
        </div>
      
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @sort-change="changeSort"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column label="编号" align="center" width="140px" type="index">
            </el-table-column>

            <el-table-column prop="companyLogo" width="180" label="logo" align="center" show-overflow-tooltip>
          <template v-slot="{ row }">
            <el-image
            v-if="row.companyLogo"
                slot="reference"
                :src="row.companyLogo"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
              <el-image
                v-else
                slot="reference"
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%203237.png"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>

             
          </template>
        </el-table-column>
            <el-table-column label="企业名称" align="center">
              <template slot-scope="scope">{{ scope.row.companyFullName }}</template>
            </el-table-column>
            <!-- <el-table-column label="所属行业" align="center">
              <template slot-scope="scope">{{ scope.row.industry }}</template>
            </el-table-column> -->
            <el-table-column label="所在城市" align="center">
              <template slot-scope="scope">{{ scope.row.province }}-{{ scope.row.city }}</template>
            </el-table-column>
            <el-table-column label="标签" align="center">
              <template v-slot="{ row }">
            <span v-for="(o, index) in row.labels" :key="index">
              <span v-if="index < 4 && o != ''" class="office">#{{ o }}</span>
            </span>
            <el-popover placement="bottom" width="230" trigger="hover">
              <div
                class="hidden_label_box"
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                <span
                  class="office"
                  v-for="(o, index) in row.labels && row.labels.length
                    ? row.labels.filter((el, index) => index > 3)
                    : []"
                  :key="index"
                  style="
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #4e93fb;
                    margin-right: 10px;
                  "
                  >#{{ o }}</span
                >
              </div>
              <span
                style="color: #4e93fb"
                slot="reference"
                v-if="row.labels && row.labels.length > 4"
                >...{{ row.labels.length - 4 }}</span
              >
            </el-popover>
          </template>
            </el-table-column>
            <el-table-column label="关联度"  align="center" width="100" prop="correlation" sortable>
              <template slot-scope="scope">
                <div class="correlationBox">
                  <div class="correlation">{{ scope.row.correlation }}%</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="企业简介" align="center" width="450">
              <template slot-scope="scope">
                <div
                  class="enterpriseIntro"
                  @mouseenter="enterpriseIntroShow = scope.row.id"
                  @mouseleave="enterpriseIntroShow = -1"
                >
                  {{ scope.row.enterpriseIntro }}
                </div>
                <div
                  class="enterpriseIntroShow"
                  v-show="enterpriseIntroShow == scope.row.id"
                  @mouseenter="enterpriseIntroShow = scope.row.id"
                  @mouseleave="enterpriseIntroShow = -1"
                >
                  {{ scope.row.enterpriseIntro }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template v-slot="{ row }">
                <div class="btn">
                  <span class="pass" v-if="row.isShelf==0" @click="isShelf(row)">下架</span>
                  <span class="un_pass" v-if="row.isShelf==1" @click="isShelf(row)">上架</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="query.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="query.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectById, pushedCompanyShelf,pushedCompanyPage } from '../../../api/content.js'
export default {
  name: 'PushEnterprise',
  computed: {
    activeId() {
      return this.$route.query.id
    }
  },
  data() {
    //这里存放数据
    return {
        headData:{},///头部信息
      // 表格数据
      tableData: [],
      loading: false,
      // 分页总条数
      total: 0,
      ids: [],
      // 表格发送数据
      query: {
        city: '',
        id: '',
        idType: '',
        orderByParam: '',
        pageNum: 1,
        pageSize: 10,
        province: '',
        queryConditions: ''
      },
      enterpriseIntroShow:false,
    }
  },

  created() {
    this.getHeadData()
    this.search()
  },
  //方法集合
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    },

    // 头部数据
    async getHeadData() {
      const { data: res } = await selectById({
        id: this.activeId
      })
      if (res.resultCode == 200) {
        this.headData = res.data
      }
    },

    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
    },
    async search() {
      this.query.id = this.activeId
      this.loading = true
      const { data: res } = await pushedCompanyPage(this.query)

      if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
        this.tableData.forEach((item) => {
          if (item.labels) {//根据字符截取标签
            item.labels = item.labels.split('~')
          }else{
            item.labels=[]
          }
        })
      }
    },
    //单个上下架
    isShelf(row) {
   
      const query = {
        companyIds:[row.id],
            id:this.activeId,
            idType:'资讯',
            isShelf:row.isShelf==0?'1':'0'
      }
       const that = this
        this.$alert(`是否确认${row.isShelf==0?'下架':'上架'}该企业？`)
          .then(function () {
            return pushedCompanyShelf(query)
          })
          .then(() => {
            this.search()
            this.$message.success(`${row.isShelf==0?'下架':'上架'}成功`)
          })
          .catch(() => {})
      
    },
     //批量下架
     updateOffShelf() {
      if (this.ids.length > 0) {
        const query={
            companyIds:this.ids,
            id:this.activeId,
            idType:'资讯',
            isShelf:1
        }
        const that = this
        this.$alert('是否确认批量下架该企业？')
          .then(function () {
            return pushedCompanyShelf(query)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.$refs.multipleTable.clearSelection()
            this.$message.success('下架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择企业')
      }
    },
     //排序
     changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'correlation') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.query.orders = '2'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.query.orders = '3'
          this.search()
        } else {
          //updateAt列不排序时
          this.query.orders = '1'
          this.search()
        }
      } 
    },
  }
}
</script>
<style scoped lang="less">
.all_context {
  padding: 16px 0px 0 0px;
  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .head {
    margin-bottom: 15px;
    height: 200px;
    display: flex;
    background-color: #ffffff;
    padding-right: 50px;
    border-radius: 8px;

    .img {
      width: 267px;
      height: 201px;
      // background-color: skyblue;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        width: 239px;
        height: 166px;
        border-radius: 4px;
      }
      
    }
    .describe {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title_label {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding-right: 50px;
        .left {
          flex: 1;
          padding-top: 19px;
          .title {
            color: #333333;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .type {
            margin: 12px 0px;
            font-size: 14px;
            color: #333;
          }
          .issueName {
            margin-bottom: 21px;
            font-size: 14px;
            color: #333;
          }
          .label {
            width: 450px;
            color: #4e93fb;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
            // 控制行数
            -webkit-line-clamp: 2; //超出两行隐藏
            -webkit-box-orient: vertical; // 从上到下垂直排列子元素

            .item {
              display: inline-block;
              margin-right: 11px;
              margin-bottom: 10px;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .total_num{
            margin-bottom: 10px;
            font-size: 30px;
            font-weight: 550;
            color: #ff7d18;
        }
         
          
        }
      }
    }
  }
  .context {
    background-color: #ffffff;
    padding: 0 21px 0 30px;
    border-radius: 8px;
    .pass{
        color: #4e93fb;
        cursor: pointer;
    }
    .un_pass{
        color: #fd6161;
        cursor: pointer;
    }
    .office {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4e93fb;
    margin-right: 10px;
    cursor: pointer;
  }
    .state {
      height: 72px;
      border-bottom: 1px dashed #f3f3f3;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        .item {
          color: #333333;
          font-size: 14px;
          cursor: pointer;
          padding: 15px 20px;
        }
      }
      .right {
        display: flex;
        padding-right: 25px;
        f
       
        .icon {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .tex {
          margin-left: 7px;
          color: #595959;
        }
        .time {
          color: #333333;
        }
      }
      /deep/.el-radio {
        margin-right: 0;
      }
    }
    .time_search {
      height: 80px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      justify-content: space-between;
      position: relative;
      .time {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        position: absolute;
        left: 0;

        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        // .item {
        //   color: #333333;
        //   font-size: 14px;
        //   cursor: pointer;
        //   padding: 15px 20px;
        // }
      }
      .search {
        display: flex;
        position: absolute;
        right: 0;
        .search_input {
          width: 430px;
        }
        .search_div {
          width: 74px;
          height: 40px;
          background-color: #448aff;
          color: #fff;
          border-radius: 2px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          transform: translateX(-2px);
        }
      }
    }

    .table {
      .imgbox {
        display: flex;
        justify-content: center;

        .img {
          width: 34px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .correlationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .correlation {
          background-color: #fd6161;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }

      .enterpriseIntro {
        // width: 100%;
        // height: 100%;
        width: 376px;
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
        // 控制行数
        -webkit-line-clamp: 2; //超出两行隐藏
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        color: #333333;
        position: relative;
      }
      .enterpriseIntroShow {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        // transform: translateX(50%);
        width: 376px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 999;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
  }
}

.add_select {
  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
  /deep/.el-dialog {
    margin-top: 40vh !important;
  }
}

.correlation {
  /deep/.el-dialog {
    border-radius: 4px;
    position: relative;
  }
  /deep/.el-dialog__body {
    padding: 28px 20px 16px 20px;
  }
  .correlationBox {
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      // transform: translate(-20px, -50px);
      .title {
        border-left: 4px solid #448aff;
        padding-left: 15px;
        font-size: 16px;
        color: #333333;
        font-weight: 500;
      }
    }
    .context {
      .input {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .label {
          position: relative;
          font-size: 14px;
          color: #333333;
          span {
            position: absolute;
            right: -9px;
            top: -2px;
            color: #f05252;
          }
        }
        /deep/.el-input {
          width: 322px;
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        .text {
          width: 64px;
          height: 36px;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background-color: #448aff;
          margin-top: 31px;
        }
      }
    }
  }
}
</style>
